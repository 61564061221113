<template>
  <vs-popup id="popupCompanyForm" classContent="popup-example" :title="$t('company.form')" :active.sync="popup">
    <div class="vx-row popup">
      <div class="vx-col sm:w-1/2 w-full mb-2">
        <vs-input class="w-full inputx sm-input" name="name" v-validate="'required'"
          :label-placeholder="$t('company.name')" v-model="name"></vs-input><span class="text-danger text-sm"
          v-if="errors.has('name')">{{
            errors.first("name")
          }}</span>
      </div>
      <div class="vx-col sm:w-1/2 w-full mb-2">
        <vs-input class="w-full inputx sm-input" name="description" :label-placeholder="$t('company.description')"
          v-model="description"></vs-input>
      </div>
      <div class="vx-col sm:w-1/2 w-full mb-2">
        <vs-input class="w-full inputx sm-input" name="db" :label-placeholder="$t('company.db')" v-model="db"></vs-input>
      </div>
      <div class="vx-col w-full mb-2 sm:w-1/2">
        <label class="vs-select--label">{{ $t('company.version') }}</label>
        <v-select :options="versiones" label="name" :clearable="false" name="version" :data-vv-as="$t('company.version')"
          v-validate="'required'" v-model="version_data" :placeholder="$t('company.version')" autocomplete="off">
          <template slot="option" slot-scope="option">{{ option.name | capitalize }}</template>
        </v-select>
        <span class="text-danger text-sm" v-show="errors.has('version')">{{ errors.first('version') }}</span>
      </div>
      <div class="vx-col sm:w-1/1 w-full mb-2" v-for="(field, index) in config" :key="index">
        <vs-divider></vs-divider>
        <vs-list>
          <vs-list-item :title="field.trad[i18n.locale]">
            <vs-switch v-model="field.value" v-if="field.type == 'Boolean'">
              <span slot="on">{{ $t("dynamic_fields.yes") }}</span>
              <span slot="off">{{ $t("dynamic_fields.not") }}</span>
            </vs-switch>
          </vs-list-item>
        </vs-list>
      </div>
      <div class="vx-col sm:w-1/1 w-full mb-2">
        <vs-divider></vs-divider>
        <vs-list>
          <vs-list-item :title="$t('company.activate_icon_cedi')">
            <vs-switch v-model="show_icon">
              <span slot="on">{{ $t('yes') }}</span>
              <span slot="off">{{ $t('not') }}</span>
            </vs-switch>
          </vs-list-item>
        </vs-list>
      </div>
      <div class="vx-col sm:w-1/1 w-full mb-2">
        <vs-divider></vs-divider>
        <vs-list>
          <vs-list-item :title="$t('company.activate_trajectory')">
            <vs-switch v-model="show_trajectory_line">
              <span slot="on">{{ $t('yes') }}</span>
              <span slot="off">{{ $t('not') }}</span>
            </vs-switch>
          </vs-list-item>
        </vs-list>
      </div>
      <div class="vx-col sm:w-1/1 w-full mb-2">
        <vs-divider></vs-divider>
        <vs-list>
          <vs-list-item :title="$t('company.delivery_time')">
            <vs-switch v-model="show_delivery_time">
              <span slot="on">{{ $t('yes') }}</span>
              <span slot="off">{{ $t('not') }}</span>
            </vs-switch>
          </vs-list-item>
        </vs-list>
      </div>
      <!-- TODO: ajust v-if -->
      <div class="vx-col w-full mb-2" v-if="!show_delivery_time">
        <v-select :options="optionsStatus" label="name" multiple v-model="statuses" name="name">
        </v-select>
      </div>
    </div>
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12">
        <vs-button class="mt-6 block offset-3" color="primary" type="filled" :disabled="isSaved" @click="setSave">
          {{ $t("devices.save") }}
        </vs-button>
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import HTTP from "@/http-common";
import ErrorNotify from "@/components/errors/Errors";
import i18n from "@/i18n.js";
import StatusServices from "@/api/status/Status";

export default {
  props: {
    company: {
      type: Object,
      required: false,
    },
    popupActivo: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      i18n: i18n,
      name: "",
      description: "",
      db: "",
      statuses: [],
      status: null,
      status_id: null,
      versiones: [],
      version_data: null,
      version_id: null,
      config: [
        {
          id: 1,
          name: "is_active_map_client",
          type: "Boolean",
          trad: {
            es: "Activar mapa del lado del cliente",
            en: "Activate client-side map",
          },
          value: false,
        },
        {
          id: 2,
          name: "is_active_map_user",
          type: "Boolean",
          trad: {
            es: "Activar mapa del lado del usuario",
            en: "Activate users-side map",
          },
          value: false,
        }
      ],
      show_trajectory_line: false,
      show_icon: false,
      show_delivery_time: false,
      optionsStatus: [],
      isSaved: false,
    };
  },
  computed: {
    popup: {
      get() {
        return this.popupActivo;
      },
      set(val) {
        if (!val) {
          this.$emit("closePopup", this.company)
          this.$store.dispatch("company/set_company", null)
        }
      },
    },
  },
  watch: {
    company(val) {
      if (val != null) {
        this.name = val.company;
        this.description = val.description;
        this.config = val.config.config;
        this.version_id = val.version
        this.db = val.db
        this.show_trajectory_line = val.show_trajectory_line
        this.show_icon = val.show_icon
        this.show_delivery_time = val.show_delivery_time
        this.statuses = []
        for (let status of this.company.statuses) {
          for (let option of this.optionsStatus) {
            if (status == option.id) {
              this.statuses.push(option)
            }
          }
        }
        if (this.version_id) {
          for (let i = 0; i < this.versiones.length; i++) {
            if (this.version_id == this.versiones[i].name) {
              this.version_data = this.versiones[i]
            }
          }
        }
      }
    },
    show_icon(val) {
      if (!val) {
        if (this.show_trajectory_line) {
          this.show_trajectory_line = false
        }
      }
    },
    show_trajectory_line(val) {
      if (val) {
        this.show_icon = true
      }
    },
    show_delivery_time(val) {
      if (val) {
        this.statuses = []
      }
    }
  },
  created() {
    this.versiones.push(
      {
        id: 20,
        name: "V3",
      },
      {
        id: 21,
        name: "V4",
      }
    );
  },
  async mounted() {
    await this.getStatuses();

  },
  methods: {
    clear() {
      this.name = null
      this.description = null
      this.version_data = null
      this.config = [
        {
          id: 1,
          name: "is_active_map_client",
          type: "Boolean",
          trad: {
            es: "Activar mapa del lado del cliente",
            en: "Activate client-side map",
          },
          value: false,
        },
        {
          id: 2,
          name: "is_active_map_user",
          type: "Boolean",
          trad: {
            es: "Activar mapa del lado del usuario",
            en: "Activate users-side map",
          },
          value: false,
        },
        {
          id: 3,
          name: "show_tracking_line",
          type: "Boolean",
          trad: {
            es: "Activar visualización de la linea de trayectoria",
            english: "Activate visualization of the trajectory line",
          },
          value: false,
        },
        {
          id: 4,
          name: "show_icon",
          type: "Boolean",
          trad: {
            es: "Activar visualización de logo del CEDI",
            en: "Activate visualization of the CEDI logo",
          },
          value: false,
        },
      ];
    },
    setSave() {
      this.isSaved = true;
      let dataFormated = {
        company: this.name,
        version: this.version_data.name,
        description: this.description,
        config: this.config,
        show_trajectory_line: this.show_trajectory_line,
        show_icon: this.show_icon,
        show_delivery_time: this.show_delivery_time,
        statuses: !this.statuses.length ? [] : this.statuses.map((row) => row.id),
        db: this.db
      };
      if (this.company == null) {
        this.createCompany(dataFormated);
      } else {
        this.updateCompany(dataFormated);
      }
    },
    updateCompany(dataFormated) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let url = `v1/companies/update/${this.company.id}`;
          HTTP.setHeader(true);
          HTTP.put(url, dataFormated)
            .then(() => {
              this.$vs.notify({
                text: this.$t("company.update"),
                color: "#64cc52",
                iconPack: "feather",
                icon: "icon-check-circle",
              });
              this.popup = false;
              this.$emit("refreshTable");
              this.isSaved = false;
              this.clear();
            })
            .catch((response) => {
              ErrorNotify.formsErrors({
                data: response.response,
                ob: this,
              });
              this.isSaved = false;
            });
        } else {
          this.isSaved = false;
        }
      });
    },
    createCompany(dataFormated) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let url = "/v1/companies/create";
          HTTP.setHeader(true);
          HTTP.post(url, dataFormated)
            .then(() => {
              this.$vs.notify({
                text: this.$t("company.create"),
                color: "#64cc52",
                iconPack: "feather",
                icon: "icon-check-circle",
              });
              this.popup = false;
              this.$emit("refreshTable");
              this.isSaved = false;
              this.clear();
            })
            .catch((response) => {
              ErrorNotify.formsErrors({
                data: response.response,
                ob: this,
              });
              this.isSaved = false;
            });
        } else {
          this.isSaved = false;
        }
      });
    },
    getStatuses() {
      StatusServices.getStatusAll().then((response) => {
        this.optionsStatus = response.data.map((row) => ({
          id: row.id,
          name: row.status,
        }));
      });
    },
  },
  beforeDestroy() {
    var elem = document.querySelector("#popupCompanyForm");
    if (elem) {
      elem.parentNode.removeChild(elem);
    }
  },
};
</script>

<style scoped> .toggle-container {
   margin-top: 10px;
   display: flex;
   justify-content: space-between;
   align-items: center;
 }

 .popup {
   max-height: 700px;
 }
</style>
